import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import { QueryResultsModel } from '../models/query-models/query-results.model';
import { QueryParamsModel } from '../models/query-models/query-params.model';

@Injectable()
export class HttpUtilsService {	

	getHTTPHeaders(): HttpHeaders {
		const result = new HttpHeaders();	
		result.set('Content-Type', 'application/json');
		return result;
	}	
}
