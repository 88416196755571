import { Component, OnInit ,ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
 import { HttpUtilsService } from './../../../content/pages/components/_core/utils/http-utils.service';
import * as globals from '../../../global';
@Component({
  selector: 'm-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  public currentURL = "";
  public description: any;
  constructor(private cd: ChangeDetectorRef,private router: Router,private http: HttpClient, private httpUtils: HttpUtilsService) { 
   this.currentURL = this.router.url.split("/").pop()
  }

  ngOnInit() {
   this.getPageInfo();
  }

  getPageInfo() {
    const headers = this.httpUtils.getHTTPHeaders();   
     this.http.get(globals.base_url + '/content/details-of-content-page?page_url=app/'+this.currentURL, {headers: headers}).subscribe(
      result => {             
        if (result && result['result'] && result['sc'] == 1) {            
          this.description = result['result'][0].page_content;                 
          this.cd.detectChanges();
        }        
      },
      error => {
        console.log("error", error);
      }
    );
  }

}
