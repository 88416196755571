import { base_url } from "../app/global";
 
export const environment = {  
    production: true,
    base_url: 'https://prodapi.aed365.com/aed365-backend-phpadminapi/public/api/',
    // base_url:'http://3.95.225.173/aed365-backend-phpadminapi/public/api/',
    BASE_RESELLER_URL:'https://reseller.aed365.com/',
    googleLocationKey: 'AIzaSyDSKO2PHGzkQE-zCsPbS0BpZQYIaM9J8w0',
    ADMIN_PANEL_URL:'https://portal.aed365.com/manage-aed/view/',
    ADMIN_PANEL_URL1:'https://portal.aed365.com/resellers/view/',
    LOGIN_COMPANY: 'https://hub.aed365.com/verify-admin?logintoken='
};