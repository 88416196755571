import { Injectable } from '@angular/core';

import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable, empty } from 'rxjs';

import { TokenStorage } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AddAuthorizationInterceptor implements HttpInterceptor {

  constructor(private tokenStorate : TokenStorage) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    let authToken = '';
    this.tokenStorate.getAccessToken().subscribe((token)=>{
      authToken = token;
    });
    if(authToken){
      const clonedRequest = req.clone({ headers: req.headers.set('Authorization', authToken) });
      return next.handle(clonedRequest);
    }
    return next.handle(req);
{

}    

    // Pass the cloned request instead of the original request to the next handle
   
  }
}
