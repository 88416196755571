import { Component, OnInit, Input } from '@angular/core';
import { of as observableOf } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  @Input() public display: boolean = false;
  @Input() public margin: string='auto auto 30px auto';
  @Input() public padding: string='2px 20px 20px 20px';
  @Input() public contentPadding: string='0';
  @Input() public showCloseButton: boolean=true;

  constructor() { }

  ngOnInit() {
  }

  public show() {
    this.display = true;
  }

  public hide() {
    this.display = false;
  }

  public hideWithDelay(delayTime ?:number) {
    observableOf(null).pipe(delay(delayTime?delayTime:2000)).subscribe(() => {
      this.display = false;
    });
  }

}
