import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteComponent } from './delete/delete.component';
import { ModalComponent } from './modal/modal.component';
@NgModule({
  imports: [
    CommonModule,   
    
  ],
  declarations: [DeleteComponent,ModalComponent],
  exports: [DeleteComponent,ModalComponent]
})
export class PopupManagementModule { }
