import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import {ProfileService} from "../../../../pages/components/_core/services/profile.service";
import { ChangeDetectorRef } from '@angular/core';
import { MenuHorizontalService } from '../../../../../core/services/layout/menu-horizontal.service';
import * as objectPath from 'object-path';

@Component({
	selector: 'm-user-profile',
	templateUrl: './user-profile.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent implements OnInit {
	@HostBinding('class')
	// tslint:disable-next-line:max-line-length
	classes = 'm-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light';

	currentUser: any = {};

	@HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'click';

	@Input() avatar: string = './assets/images/user-image.png';
	@Input() avatarBg: SafeStyle = '';

	@ViewChild('mProfileDropdown') mProfileDropdown: ElementRef;

	constructor (
		private router: Router,
		private authService: AuthenticationService,
		private profileService: ProfileService,
		private sanitizer: DomSanitizer,
		public menuHorService: MenuHorizontalService,
		private cd: ChangeDetectorRef
	) {
		this.currentUser = JSON.parse(localStorage.getItem('userData'));
	}

	ngOnInit (): void {
		if (!this.avatarBg) {
			this.avatarBg = this.sanitizer.bypassSecurityTrustStyle('');
		}
		this.profileService._name.subscribe(res => {
			if(res){
				this.currentUser.name = res;
				this.cd.detectChanges();
			}		
		});
		this.profileService._email.subscribe(res => {
			if(res){
				this.currentUser.email = res;
				this.cd.detectChanges();
			}			
		});
		this.profileService._image.subscribe(res => {
			if(res){
				this.currentUser.image = res;
				this.cd.detectChanges();
			}			
		});
	}

	public logout () {
		const result = confirm('Are you sure you want to logout?');
		if(result)
		{
			this.authService.logout(true);
		}
	}

	getProfilePic(img) {
		let st: any;
		if (img) {
			st = { background: `url(${img}) center/cover no-repeat` };
		}  else {
			st = { background: `url(${this.avatar}) center/cover no-repeat` };
		}
		return st;
	}

	goToProfile(){
		let b = [{title: 'My Profile'}];
		this.menuHorService.menuList$.next(objectPath.get(b));
		this.router.navigate(['/profile']);
	}
}
