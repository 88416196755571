import { Component, OnInit, Input, Output, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../../../core/auth/authentication.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'm-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss']
})
export class VerifyAccountComponent implements OnInit {

  @Input() action: string;
  @Output() actionChange = new Subject<string>();
  params: any;
  successVeried: boolean = false;

  constructor(
              private route: ActivatedRoute,
              private authService: AuthenticationService,
              private cdr: ChangeDetectorRef,
              private toastr: ToastrService,
              private _router: Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.params = params;
      if (this.params.hash) {
        let clean_uri = location.protocol + "//" + location.host + location.pathname;
        window.history.replaceState({}, document.title, clean_uri);
        this.verifyAccount(this.params.hash);
      }
    });
  }

  /*navigateToLoginPage(){
    this._router.navigate(['/login']);
  }*/

  verifyAccount(key) {
    let param = {'verify_token' : key};
    this.authService.verifyAccount(param).subscribe(data => {

      if(data && data['sc'] == 1){
        this.successVeried = true;
        this.cdr.detectChanges();
      }
      if(data && data['sc'] == 0 && data['error']){
        this.toastr.error(data['error']['msg'], 'Error');
        // this.navigateToLoginPage();
      }

    });
  }

}
