import { Injectable } from '@angular/core';

@Injectable()

export class AuthUrlsService {


  API_ADMIN_URL = 'https://api.beno.com/benoadminapi/api/v0';
  API_USER_URL = 'https://api.beno.com/beno/api/v0';
  API_ADMIN_PANNEL = '/admin';
  API_USER_PANNEL = '/user';
	API_ENDPOINT_LOGIN = '/login';
	API_ENDPOINT_REFRESH = '/refresh';
  API_ENDPOINT_FORGOT_PWD = '/forgot_pwd';
  API_ENDPOINT_UPDATE_PROFILE = '/updateProfile';
  API_ENDPOINT_GET_PROFILE = '/getProfile';
//	API_ENDPOINT_REGISTER = '/register';

  constructor() { }

  getAuthUrls(){
  return {
    API_ADMIN_URL : this.API_ADMIN_URL,
    API_USER_URL : this.API_USER_URL,
    API_ENDPOINT_LOGIN : this.API_ENDPOINT_LOGIN,
    API_ENDPOINT_REFRESH : this.API_ENDPOINT_REFRESH,
    API_ADMIN_PANNEL:this.API_ADMIN_PANNEL,
    API_USER_PANNEL:this.API_USER_PANNEL,
    API_ENDPOINT_FORGOT_PWD : this.API_ENDPOINT_FORGOT_PWD,
    API_ENDPOINT_UPDATE_PROFILE : this.API_ENDPOINT_UPDATE_PROFILE,
    API_ENDPOINT_GET_PROFILE: this.API_ENDPOINT_GET_PROFILE
    }
  }
}
