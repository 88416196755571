import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../../../core/auth/authentication.service';

@Component({
    selector: 'm-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    public resetPasswordForm: FormGroup;
    public submitted = false;
    token: any;

    constructor(private formBuilder: FormBuilder,
                private _routeParams: ActivatedRoute,
                private toastr: ToastrService,
                private authService: AuthenticationService,
                private router: Router) {

                    this._routeParams.queryParams.subscribe(params => {
                        this.token = params['hash'];     
                        if(this.token){
                          let clean_uri = location.protocol + "//" + location.host + location.pathname;
                          window.history.replaceState({}, document.title, clean_uri);
                        }
                      });
        /*this._routeParams.queryParams.subscribe(params => {
         this.token = params['hash'];
         if(this.token){
         let clean_uri = location.protocol + "//" + location.host + location.pathname;
         window.history.replaceState({}, document.title, clean_uri);
         }
         });
         */

    }

    ngOnInit() {
        this.resetPasswordForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(8)]],
            confirmPassword: ['', [Validators.required]],
        }, {validator: this.checkIfMatchingPasswords('password', 'confirmPassword')});

    }

    checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
        return (group: FormGroup) => {
            let passwordInput = group.controls[passwordKey],
                passwordConfirmationInput = group.controls[passwordConfirmationKey];
            if (passwordInput.value !== passwordConfirmationInput.value) {
                return passwordConfirmationInput.setErrors({notEquivalent: true})
            }
            else {
                return passwordConfirmationInput.setErrors(null);
            }
        }
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.resetPasswordForm.controls;
    }

    onSubmit(params) {
       // var a = this._routeParams.snapshot.paramMap.get('token');
        let param = {
            token: this.token,
            password: params.password,
            password_confirmation: params.confirmPassword
        };
        this.submitted = true;
        // stop here if form is invalid
        if (this.resetPasswordForm.invalid) {
            return;
        } else {
            this.authService.resetPassword(param).subscribe(data => {

                if (data && data['success']) {
                    this.toastr.success('Your password has been reset successfully', 'Success');
                    this.router.navigate(['/login']);
                }else{
                    this.toastr.error(data['message'], 'Error');
                }
               

            }, error => {
                this.toastr.error(error.error.message, 'Error');
            });
        }
    }

    showError(msg) {
        this.toastr.error(msg, '', {
            timeOut: 5000
        });
    }

    showSuccess(msg) {
        this.toastr.success(msg, '', {
            timeOut: 1000
        });
    }
}
