import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WindowScrolling } from "../../../content/pages/components/_shared/window-scroll.service";

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css']
})
export class DeleteComponent implements OnInit {
  @Input() public item:any={};
  @Input() public title:string='Delete a Feed';
  @Input() public bodyText:string='Are you sure you want to delete this RSS Feed?';
  @Input() public buttonText:string='Delete Now';
  @Output() public onDelete:EventEmitter<any> = new EventEmitter<any>();

  constructor(private windowScrolling: WindowScrolling) { }

  ngOnInit() {
    this.windowScrolling.disable();
  }

  ngOnDestroy(){
    this.windowScrolling.enable();
  }

}
